import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import type { Referential, SearchProductsQueryParameters, VehicleResponse } from '@yescapa-dev/ysc-api-js/legacy'
import type { LocationQueryRaw } from '#vue-router'

export const vehicleEquipmentsOverview = ['consummables', 'dishes_kit', 'base_kit', 'fridge', 'bicycle', 'shower_int', 'wc', 'cleaning_kit']

export const equipmentsCategoryMap: Record<string, {
  category: string
  isNumber?: boolean
  referentialOptionsKey?: keyof Referential['options']
  unit?: string | Intl.NumberFormatOptions
}> = {
  power_steering: {
    category: 'driving',
  },
  cruise_control: {
    category: 'driving',
  },
  four_wheel_drive: {
    category: 'driving',
  },
  gps: {
    category: 'driving',
  },
  parking_aid_radar: {
    category: 'driving',
  },
  parking_aid_camera: {
    category: 'driving',
  },
  autoradio: {
    category: 'driving',
  },
  cd_player: {
    category: 'driving',
  },
  bluetooth: {
    category: 'driving',
  },
  ipod_input: {
    category: 'driving',
  },
  heating: {
    category: 'driving',
  },
  ac: {
    category: 'driving',
  },
  power_locked: {
    category: 'driving',
  },
  baby_seat: {
    category: 'driving',
  },
  child_seat: {
    category: 'driving',
  },
  winter_tire: {
    category: 'driving',
  },
  winter_chain: {
    category: 'driving',
  },
  wedge: {
    category: 'driving',
  },
  security_pack: {
    category: 'driving',
  },
  tv: {
    category: 'life_on_board',
  },
  dvd_player: {
    category: 'life_on_board',
  },
  satellite: {
    category: 'life_on_board',
  },
  cleaning_kit: {
    category: 'life_on_board',
  },
  consummables: {
    category: 'life_on_board',
  },
  ac_premium: {
    category: 'life_on_board',
  },
  heating_premium: {
    category: 'life_on_board',
  },
  bedding: {
    category: 'life_on_board',
  },
  swivel_seat_driver: {
    category: 'life_on_board',
  },
  swivel_seat_passenger: {
    category: 'life_on_board',
  },
  extinguisher: {
    category: 'life_on_board',
  },
  sink: {
    category: 'kitchen_meal',
  },
  cooking: {
    category: 'kitchen_meal',
  },
  oven: {
    category: 'kitchen_meal',
  },
  fridge: {
    category: 'kitchen_meal',
  },
  freezer: {
    category: 'kitchen_meal',
  },
  extractor: {
    category: 'kitchen_meal',
  },
  cookware: {
    category: 'kitchen_meal',
  },
  coffee_maker: {
    category: 'kitchen_meal',
  },
  table: {
    category: 'kitchen_meal',
  },
  dishes_kit: {
    category: 'kitchen_meal',
  },
  dishes_count: {
    category: 'kitchen_meal',
    isNumber: true,
  },
  meal_seats: {
    category: 'kitchen_meal',
    isNumber: true,
  },
  shower_int: {
    category: 'toilet',
  },
  shower_ext: {
    category: 'toilet',
  },
  wc: {
    category: 'toilet',
  },
  wc_portable: {
    category: 'toilet',
  },
  bathroom_sink: {
    category: 'toilet',
  },
  bicycle: {
    category: 'outside',
  },
  bicycle_count: {
    category: 'outside',
    isNumber: true,
  },
  side_blind: {
    category: 'outside',
  },
  camping_table: {
    category: 'outside',
  },
  barbecue: {
    category: 'outside',
  },
  towbar: {
    category: 'outside',
  },
  disabled_access: {
    category: 'outside',
  },
  sout_luggage: {
    category: 'outside',
  },
  sout_garage: {
    category: 'outside',
  },
  sout_adjustable: {
    category: 'outside',
  },
  chair_count: {
    category: 'outside',
    isNumber: true,
  },
  solar: {
    category: 'autonomy-details',
  },
  electric_linking: {
    category: 'autonomy-details',
  },
  electric_plug_adaptator: {
    category: 'autonomy-details',
  },
  auxiliary_battery: {
    category: 'autonomy-details',
  },
  generator: {
    category: 'autonomy-details',
  },
  heating_energies: {
    category: 'autonomy-details',
    referentialOptionsKey: 'energie_types',
  },
  electric_plug_types: {
    category: 'autonomy-details',
    referentialOptionsKey: 'energie_types',
  },
  cooking_energies: {
    category: 'autonomy-details',
    referentialOptionsKey: 'energie_types',
  },
  fridge_energies: {
    category: 'autonomy-details',
    referentialOptionsKey: 'energie_types',
  },
  solar_count: {
    category: 'autonomy-details',
    isNumber: true,
    unit: 'data.vehicle.equipments.solar_count.unit',
  },
  autonomy: {
    category: 'autonomy-details',
    isNumber: true,
    unit: { unit: 'day', unitDisplay: 'long' },
  },
  others: {
    category: 'others',
  },
}

/**
 * @param date_from date_to
 *  formatted as yyyy-MM-dd
 */
export const getSimilarQuery = (
  { id, vehicle, date_from, date_to, page_size = 3 }:
  { id: number, vehicle: VehicleResponse, date_from?: string | null, date_to?: string | null, page_size?: number },
) => {
  const query: SearchProductsQueryParameters = {
    page_size,
    product_id__not_in: `${id}`,
    latitude: vehicle.location?.latitude,
    longitude: vehicle.location?.longitude,
    radius: 10000,
  }

  if (date_from && date_to) {
    query.date_from = date_from
    query.date_to = date_to
  }

  // group similar types
  const {
    LOWPROFILE,
    COACHBUILT,
    ACLASS,
    CAMPERVAN,
    VAN,
  } = Constants.PRODUCTS.TYPES
  const types = []
  if (vehicle.type === LOWPROFILE || vehicle.type === COACHBUILT || vehicle.type === ACLASS) {
    types.push(LOWPROFILE, COACHBUILT, ACLASS)
  }
  else if (vehicle.type === CAMPERVAN || vehicle.type === VAN) {
    types.push(CAMPERVAN, VAN)
  }
  else {
    // caravan or others
    types.push(vehicle.type)
  }
  query.types = types.join(',')

  return query as LocationQueryRaw
}
